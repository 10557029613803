<template>
  <div class="cal-events">
    <v-app id="cal">
      <v-row>
        <v-col
          cols="12"
          md="2">
          <v-navigation-drawer permanent>
            <div class="cal-sidebar">
            <div class="team-select">
              <v-card>
                <v-card-title class="cal-title">
                  Select Team
                </v-card-title>
                <v-card-text>
                  <v-btn
                      v-for="team in colors"
                      :key="team.team"
                    x-small
                    class="team-btn"
                    :color="team.color"
                      @click="filterTeam(team.team)"
                  >
                    {{ team.fullTeam }}
                  </v-btn>
                </v-card-text>
              </v-card>
            </div>
            <div class="type-select">
              <v-card>
                <v-card-title class="cal-title">
                  Event Type
                </v-card-title>
                <v-card-text>
                  <v-radio-group v-model="eventType"  @click="filterType(type)">
                    <div class="cal-type-radio"
                         v-for="(type, index) in eventTypes"
                         :key="index"
                    >
                      <v-radio :label="type" :value="type"></v-radio>
                    </div>
                  </v-radio-group>
                </v-card-text>
              </v-card>
            </div>
          <v-menu bottom right>
            <template v-slot="{ on, attrs }">
              <v-btn
                outlined
                color="blue darken-2"
                v-bind="attrs"
                v-on="on">
                Type: <span class="event-type">{{ typeToLabel[eventType] }}</span>
                <v-icon right>mdi-menu-down</v-icon>
              </v-btn>
            </template>
            <v-list>
              <v-list-item @click="eventType = 'All'">
                <v-list-item-title>All</v-list-item-title>
              </v-list-item>
              <v-list-item @click="eventType = 'OnIce'">
                <v-list-item-title>On Ice</v-list-item-title>
              </v-list-item>
              <v-list-item @click="eventType = 'Dance'">
                <v-list-item-title>Dance</v-list-item-title>
              </v-list-item>
              <v-list-item @click="eventType = 'Virtual'">
                <v-list-item-title>Virtual</v-list-item-title>
              </v-list-item>
              <v-list-item @click="eventType = 'Other'">
                <v-list-item-title>Other</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </div>
          </v-navigation-drawer>
        </v-col>
        <v-col
          cols="12"
          md="10">
          <v-sheet height="600">
          <div class="cal-main">
            <div class="cal-head">
              <v-btn
                  icon
                  class="ma-2 cal-prev"
                  @click="$refs.calendar.prev()">
                <v-icon x-large color="blue darken-2">mdi-chevron-left-circle</v-icon>
                Previous
              </v-btn>
              <v-toolbar-title>
                <div v-if="$refs.calendar">
                  {{ $refs.calendar.title }}
                </div>
                <div v-else>
                  {{ todayTitle() }}
                </div>
                Team: {{ teamFullName}} / Event Type: {{ eventType }}
              </v-toolbar-title>
              <v-btn
                  icon
                  class="cal-next"
                  @click="$refs.calendar.next()">
                Next
                <v-icon x-large color="blue darken-2">mdi-chevron-right-circle</v-icon>
              </v-btn>
            </div>
            <v-calendar
                ref="calendar"
                v-model="value"
                :key="eventsCur"
                :weekdays="weekday"
                :type="type"
                :eventType="eventType"
                :events="eventsCur"
                :event-overlap-mode="mode"
                :event-overlap-threshold="30"
                :event-color='getEventColor'
                @click:event="showEvent"
            ></v-calendar>
            <v-menu
              v-model="openEvent"
              :close-on-content-click="false"
              :activator="selectedElement"
              offset-x>
              <v-card
                width="400"
                color="light-blue lighten-5"
                class="event-card"
                flat>
                <v-toolbar
                    dense
                    :color="selectedEvent.color"
                    dark
                >
                  <v-toolbar-title v-html="selectedEvent.name"></v-toolbar-title>
                </v-toolbar>
                <v-card-subtitle>Duration: {{ selectedEvent.eventTime }} hr(s)</v-card-subtitle>
                <v-card-text v-html="selectedEvent.details"></v-card-text>
              </v-card>
            </v-menu>
          </div>
        </v-sheet>
        </v-col>
      </v-row>
    </v-app>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: "CalEvents",
  data: () => ({
    type: 'month',
    types: ['month', 'week', 'day'],
    eventType: 'All',
    eventTypes: ['All', 'OnIce', 'Virtual', 'Dance', 'Other'],
    openEvent: false,
    selectedEvent: [],
    selectedElement: null,
    mode: 'stack',
    weekday: [0, 1, 2, 3, 4, 5, 6],
    team: 'all',
    colors: [
      { team: 'all', color: "red darken-4", fullTeam: 'All' },
      { team: 'int', color: 'blue', fullTeam: 'Intermediate' },
      { team: 'oj', color: 'indigo', fullTeam: 'Open Juvenile' },
      { team: 'pj', color: 'cyan', fullTeam: 'Pre-Juvenile' },
      { team: 'pre', color: 'teal', fullTeam: 'Preliminary' },
      { team: 'asp', color: 'purple', fullTeam: 'Aspire' },
      { team: 'adult', color: 'green', fullTeam: 'Adult' }
    ],
    months: ['January',
      'February',
      'March',
      'April',
      'May',
      'June',
      'July',
      'August',
      'September',
      'October',
      'November',
      'December'],
    value: '',
    // eventsCur: [],
  }),
  computed: {
    ...mapGetters(['calEntries']),
    eventsCur() {
      const events = []
      // const calEntries = this.calEntries
      this.calEntries.forEach(eventRaw => {
        const name = eventRaw.event
        const start = eventRaw.start
        const end = eventRaw.end
        const eventTime = eventRaw.eventTime
        const team = eventRaw.team
        const details = eventRaw.details
        const type = eventRaw.type
        const colors = this.colors
        // const eventType = this.eventType

        let color = ''
        for (let i = 0; i < colors.length; i++) {
          if (colors[i].team === team) {
            color = colors[i].color
          }
        }
        console.log(`Just before the filter, the team is ${this.team}`)
        if (this.team === 'all') {
          events.push({
            name: name,
            start: start,
            end: end,
            eventTime: eventTime,
            color: color,
            team: team,
            details: details,
            type: type,
          })
        } else {
          if (team === this.team) {
            events.push({
              name: name,
              start: start,
              end: end,
              eventTime: eventTime,
              color: color,
              team: team,
              details: details,
              type: type,
            })
          }
        }
      })

      // Filter type of events if not All
      if (this.eventType !== 'All') {
        console.log(`This ${this.eventType} should filter the pile`)
        let filterEvent = []
        events.forEach(event => {
          if (event.type === this.eventType) {
            filterEvent.push(event)
          }
        })

        return filterEvent
      }
      return events
    },
    teamFullName() {
      let fullTeam = 'All'
      this.colors.forEach(color => {
        if (color.team === this.team) {
          fullTeam = color.fullTeam
        }
      })
      return fullTeam;
    }
  },
  methods: {
    getEventColor (event) {
      return event.color
    },
    filterTeam: function (teamCode) {
      console.log(teamCode)
      this.team = teamCode
      return this.eventsCur
    },
    filterType: function () {
      // this.eventType = typeCode
      return this.eventsCur
    },
    showEvent({ nativeEvent, event }) {
      const open = () => {
        console.log(`showEvent ${JSON.stringify(event)}`)
        this.selectedEvent = event
        this.selectedElement = nativeEvent.target
        setTimeout(() => this.openEvent = true, 15)
      }
      if (this.openEvent) {
        this.openEvent = false
        setTimeout(open, 10)
      } else {
        open()
      }
      nativeEvent.stopPropagation()
    },
    todayTitle () {
      const today = new Date()
      const todayMonth = today.getMonth()
      const todayYear = today.getFullYear()

      return  this.months[todayMonth] + ' ' + todayYear
    },

  },
}
</script>

<style lang="scss">
@import "../assets/styles/components/calevents.scss";

</style>
