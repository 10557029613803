<template>
  <v-app>
    <page-header></page-header>
    <div v-if="curRoute !== 'LogIn'">
      <nav-header></nav-header>
    </div>
    <div class="loading-main" v-if="isLoading">
      <h3>Loading Calendar</h3>
    </div>
    <div v-else>
      <v-main>
        <router-view/>
      </v-main>
    </div>
  </v-app>
</template>

<script>
import NavHeader from './components/NavHeader'
import PageHeader from "@/components/PageHeader";
import { mapGetters } from 'vuex'

export default {
  name: 'App',
  components: {
    NavHeader,
    PageHeader,
  },
  computed: {
    ...mapGetters({
      isLoading: 'isLoading'
    }),
    curRoute: function() {
      return this.$route.name
    }
  },
  async mounted() {
    let curRoute = this.$route.name
    if (curRoute !== 'LogIn') {
      this.$store.dispatch('setLoading')
      await this.$store.dispatch('initializeCal')
      this.$store.dispatch('resetLoading')
    }
  }
};
</script>
