<template>
  <div class="event-list">
    <h3 class="event-header">Upcoming Events</h3>
    <p>Select for individual team schedules</p>
    <v-select
      :items="colors"
      item-value="team"
      item-text="fullTeam"
      v-model="team"
      return-object
      single-line
      dense
      @change="filterTeam(team.team)"
      >
    </v-select>
    <!--<ul>
      <li v-for="uEvent in upcomingEvents" :key="uEvent.name">
        {{ uEvent.name }}
      </li>
    </ul>-->
    <v-expansion-panels focusable>
      <v-expansion-panel
          v-for="(uEvent, index) in upcomingEvents"
          :key="index">
        <v-expansion-panel-header>
          {{ uEvent.start | dateOnly }} <br>
          {{ uEvent.name }}
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          {{ uEvent.start | timeOnly }} - {{ uEvent.end | timeOnly }}
          <div class="event-detail"> {{ uEvent.details }}</div>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: "EventList",
  data: () => ({
    team: 'all',
    eventsCur: [],
    colors: [
      { team: 'all', color: "red darken-4", fullTeam: 'All' },
      { team: 'int', color: 'blue', fullTeam: 'Intermediate' },
      { team: 'oj', color: 'indigo', fullTeam: 'Open Juvenile' },
      { team: 'pj', color: 'cyan', fullTeam: 'Pre-Juvenile' },
      { team: 'pre', color: 'teal', fullTeam: 'Preliminary' },
      { team: 'adult', color: 'green', fullTeam: 'Adult' }
    ],
  }),
  computed: {
    ...mapGetters({
      calEntries: 'calEntries'
    }),
    upcomingEvents() {
      let today = new Date()
      const eventList = [];
      let j = 0
      console.log(`Today is ${today}`)
      this.calEntries.forEach(eventRaw => {
          const start = eventRaw.start
          let startDate = new Date(start)

          const name = eventRaw.event
          const end = eventRaw.end
          const team = eventRaw.team
          const details = eventRaw.details
          const location = eventRaw.location
          const colors = this.colors
          let color = ''
          for (let i = 0; i < colors.length; i++) {
            if (colors[i].team === team) {
              color = colors[i].color
            }
          }

          if (startDate > today && j < 20) {
            if (this.team === 'all') {
              eventList.push({
                name: name,
                start: start,
                end: end,
                color: color,
                team: team,
                details: details,
                location: location,
              })
              j++
            }
            else {
              if (team === this.team) {
                eventList.push({
                  name: name,
                  start: start,
                  end: end,
                  color: color,
                  team: team,
                  details: details,
                })
                j++
              }
            }
          }
        })
      return eventList
    },
    currentTeam() {
      const colors = this.colors
      const team = this.team
      let curTeam = ''

      for (let i = 0; i < colors.length; i++) {
        if (colors[i].team === team) {
          curTeam = colors[i].fullTeam
        }
      }
      return curTeam;
    }
  },
  methods: {
    filterTeam: function (teamCode) {
      console.log(teamCode)
      this.team = teamCode
      // return this.upcomingEvents
    },
  }
}
</script>

<style lang="scss">
.event-list {
  background-color: cornflowerblue;
  width: 30%;
  h3 {
    padding: 3px 15px;
  }

  .v-expansion-panel-header {
    font-size: .8em;
    font-weight: 600;
  }
  .v-expansion-panel-content {
    .v-expansion-panel-content__wrap {
      font-size: 0.8rem;
    }
  }

}
</style>
