import { render, staticRenderFns } from "./CalEvents.vue?vue&type=template&id=54787963&"
import script from "./CalEvents.vue?vue&type=script&lang=js&"
export * from "./CalEvents.vue?vue&type=script&lang=js&"
import style0 from "./CalEvents.vue?vue&type=style&index=0&id=54787963&prod&lang=scss&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports