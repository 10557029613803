import { VExpansionPanel } from 'vuetify/lib/components/VExpansionPanel';
import { VExpansionPanelContent } from 'vuetify/lib/components/VExpansionPanel';
import { VExpansionPanelHeader } from 'vuetify/lib/components/VExpansionPanel';
import { VExpansionPanels } from 'vuetify/lib/components/VExpansionPanel';
import { VSelect } from 'vuetify/lib/components/VSelect';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"event-list"},[_c('h3',{staticClass:"event-header"},[_vm._v("Upcoming Events")]),_c('p',[_vm._v("Select for individual team schedules")]),_c(VSelect,{attrs:{"items":_vm.colors,"item-value":"team","item-text":"fullTeam","return-object":"","single-line":"","dense":""},on:{"change":function($event){return _vm.filterTeam(_vm.team.team)}},model:{value:(_vm.team),callback:function ($$v) {_vm.team=$$v},expression:"team"}}),_c(VExpansionPanels,{attrs:{"focusable":""}},_vm._l((_vm.upcomingEvents),function(uEvent,index){return _c(VExpansionPanel,{key:index},[_c(VExpansionPanelHeader,[_vm._v(" "+_vm._s(_vm._f("dateOnly")(uEvent.start))+" "),_c('br'),_vm._v(" "+_vm._s(uEvent.name)+" ")]),_c(VExpansionPanelContent,[_vm._v(" "+_vm._s(_vm._f("timeOnly")(uEvent.start))+" - "+_vm._s(_vm._f("timeOnly")(uEvent.end))+" "),_c('div',{staticClass:"event-detail"},[_vm._v(" "+_vm._s(uEvent.details))])])],1)}),1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }