import Vue from 'vue'
import moment from 'moment'

Vue.filter('toInt', (value) => {
  if (!value) {
    return ''
  }
  return value = parseInt(value)
})

Vue.filter('dateOnly', (value) => {
  if (!value) {
    return ''
  }
  return moment(value).format("MMM DD YYYY")
})

Vue.filter('timeOnly', (value) => {
  if (!value) {
    return ''
  }
  return moment(value).format("HH:mm A")
})
