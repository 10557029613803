<template>
  <div class="cost-sum">
    <h2>Ice Use Trends</h2>
    <team-ytd-ice class="col-left" />
    <venue-use class="col-right" ></venue-use>
  </div>
</template>

<script>
import VenueUse from '@/components/VenueUse'
import TeamYtdIce from '../components/TeamYtdIce.vue';


export default {
  name: "Costs",
  components: {
    VenueUse,
    TeamYtdIce
  },
    mounted() {
    this.$store.dispatch('setVenueSummary'),
    this.$store.dispatch('getYtdIce')
  }
}
</script>

<style scoped lang="scss">
.cost-sum {
  width: 90%;
  margin: 1rem auto;
}
.col-left {
  display: inline-block;
  width: 20%;
  vertical-align: top;
}
.col-right {
  margin-left: 1rem;
  display: inline-block;
  width: 75%;
}

</style>
