import Vue from 'vue'
import App from './App.vue'
import Moment from 'vue-moment'
import JsonExcel from 'vue-json-excel'
import router from './router'
import store from './store'
import '@/plugins/filters'
import vuetify from './plugins/vuetify';

Vue.use(Moment)
Vue.component('downloadExcel', JsonExcel)
Vue.config.productionTip = false

Vue.filter('dateOnly')

new Vue({
  router,
  store,
  vuetify,
  render: function (h) { return h(App) }
}).$mount('#app')
