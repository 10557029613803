import vueAxios from './vueAxios.js'

export async function loginUser(loginInfo) {
  const loginUrl = '/user/auth'

  return await vueAxios.post(loginUrl, {
    headers: {
      'Content-Type': 'application/json'
    },
    
    "username": loginInfo.username,
    "password": loginInfo.password
    
  })
  .then((response) => {
    console.log(`login return ${JSON.stringify(response)}`)
    return response.data
  })
  .catch((err) => {
    console.log('login error ', err)
  })

}