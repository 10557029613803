<template>
  <div class="calendar-home">
    <cal-events></cal-events>
    <hr>
    <update-cal-button></update-cal-button>
  </div>
</template>

<script>
import CalEvents from '@/components/CalEvents'
import UpdateCalButton from "@/components/UpdateCalButton";
// import CalSideBar from "@/components/CalSideBar";

export default {
  name: 'Calendar',
  data() {
    return {
    }
  },
  components: {
    CalEvents,
    UpdateCalButton
    // CalSideBar
  },

}
</script>

<style>

</style>
