import { render, staticRenderFns } from "./TeamYtdIce.vue?vue&type=template&id=16a8c9a4&scoped=true&"
import script from "./TeamYtdIce.vue?vue&type=script&lang=js&"
export * from "./TeamYtdIce.vue?vue&type=script&lang=js&"
import style0 from "./TeamYtdIce.vue?vue&type=style&index=0&id=16a8c9a4&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "16a8c9a4",
  null
  
)

export default component.exports