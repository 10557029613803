<template>
  <div class="page-header">
    <div class="logo">
      <img src="../assets/images/TremorsShakeLogo.png">
    </div>
    <div class="header-body">
      <h3>San Francisco Synchronized Skating Teams</h3>
    </div>
  </div>
</template>

<script>
export default {
name: "PageHeader.vue"
}
</script>

<style lang="scss">
@import "../assets/styles/components/pageheader";
</style>
