import vueAxios from './vueAxios.js'

export function fetchFullCal() {
	const calUrl = '/tremCal'

  return vueAxios.get(calUrl)
  .then((response) => {
    console.log(`FullCal response ${JSON.stringify(response)}`)
    return response.data
  })
  .catch((err) => {
    console.log(`Error in returning calendar ${err}`)
  })
}

export function fetchMoSummary() {
  const venueUrl = '/venues/cost'

  return vueAxios.get(venueUrl)
    .then((response) => {
      console.log(`FullCal response ${JSON.stringify(response)}`)
      return response.data
    })
    .catch((err) => {
      console.log(`Error in returning calendar ${err}`)
    })
}

export async function getYtdIce() {
  const ytdIceUrl = '/trem-ytdice'
  return await vueAxios.get(ytdIceUrl)
  .then((response) => {
    console.log(`IceUse return ${JSON.stringify(response)}`)
    return response.data
  })
  .catch((err) => {
    console.log('IceYTD error ', err)
  })

}
export async function updateCalBase() {
  const calUpUrl = '/tremCalDbUp'

  return await vueAxios.get(calUpUrl)
    .then((response) => {
      console.log(`Update Cal response is ${JSON.stringify(response)}`)
      return response.data
    })
    .catch((err) => {
      console.log(`Error on updating calendar ${err}`)
    })
}
