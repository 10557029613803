<template>
  <div class="login">
    <h2>Login</h2>
    <div>
      <form @submit.prevent="submit">
        <div>
          <label for="username">Username</label>
          <input type="text" name="username" v-model="form.username" />
        </div>
        <div>
          <label for="password">Password</label>
          <input type="password" name="password" v-model="form.password" />
        </div>
        <button type="submit">Log In</button>
      </form>
      <p v-if="showError" id="error">Username or Password is incorrect</p>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex';

export default {
  name: "login",
  data() {
    return {
      form: {
        username: "",
        password: "",
      },
      showError: false
    }
  },
  methods: {
    ...mapActions(["LogIn"]),
    async submit() {
      /* const User = new FormData()
      User.append("username", this.form.username)
      User.append("password", this.form.password) */
      const loginInfo = {
        'username': this.form.username,
        'password': this.form.password
      }
      try {
        await this.LogIn(loginInfo)
        this.$store.dispatch('setLoading')
        await this.$store.dispatch('initializeCal')
        this.$router.push("/")
        this.showError = false;
      }
      catch (error) {
        this.showError = true
        console.log(error)
      }

    }
  }
}
</script>

<style scoped lang="scss">
.login {
  width: 50%;
  margin: 2rem auto;
  text-align: center;
  h2 {
    text-align: center;
  }
  * {
  box-sizing: border-box;
  }
  label {
    padding: 12px 12px 12px 0;
    display: inline-block;
  }
  button[type="submit"] {

    background-color: darkblue;
    color: white;
    padding: 12px 20px;
    cursor: pointer;
    border-radius: 10px;
    margin-top: 2rem;
  }
  button[type="submit"]:hover {
    background-color: blue;
  }
  input {
    margin: 5px;
    box-shadow: 0 0 15px 4px rgba(0, 0, 0, 0.06);
    padding: 10px;
    border-radius: 10px;
    margin-top: 1rem;
  }
  #error {
    color: red;
  }
}
</style>
