import { fetchFullCal, fetchMoSummary, getYtdIce } from '../utils/calendarService.js'

const calBase = () => {
  return {
    calCont: [],
    venueSummary: [],
    monthHeader: {},
    ytdIce: []
  }
}

const state  = calBase();

const mutations = {
  UPDATE_BASE_CALENDAR: (state, { cal }) => {
    console.log(`'Update base with ${JSON.stringify(cal)}`)
    state.calCont = cal
  },
  UPDATE_VENUE_SUMMARY: (state, { venueSummary }) => {
    state.venueSummary = venueSummary
  },
  UPDATE_MONTH_HEADER: (state, { monthHeader }) => {
    state.monthHeader = monthHeader
  },
  UPDATE_YTD_ICE: (state, { ytdIce }) => {
    state.ytdIce = ytdIce
  }
}

const actions = {
  initializeCal: async function ({ commit, dispatch },) {

    return await fetchFullCal()
    .then((response) => {
      console.log(`What do we dte back ${JSON.stringify(response)}`)
      commit('UPDATE_BASE_CALENDAR', { cal: response.events })
      dispatch('resetLoading')
    })
    .catch((err) => {
      console.log(`Cal init err ${err}`)
    })
  },
  setVenueSummary: async function ({ commit }, ) {
    return await fetchMoSummary()
    .then((response) => {
      console.log(`Returned Venue Query ${JSON.stringify(response)}`)
      commit('UPDATE_VENUE_SUMMARY', { venueSummary: response.teamTimes })
      commit('UPDATE_MONTH_HEADER', { monthHeader: response.monthHeader})
    })
    .catch((err) => {
      console.log(`Error returned from venueSummary ${err}`)
    })
  },
  getYtdIce: async function ({ commit }) {
    return await getYtdIce()
    .then((response) => {
      commit('UPDATE_YTD_ICE', { ytdIce: response })
    })
    .catch((err) => {
      console.log('State getYtdIce error', err)
    })
  }
}

const getters = {
  calEntries: state => {
    return state.calCont
  },
  venueSummary: state => {
    return state.venueSummary
  },
  ytdIce: state => {
    return state.ytdIce
  }
}

export default {
  state,
  mutations,
  actions,
  getters,
}
