import { loginUser } from '../utils/authService';
import axios from 'axios';

const state = {
  user: null,
  posts: null,

};
const getters = {
  isAuthenicated: state => !!state.user,
  StatePosts: state => state.posts,
  StateUser: state => state.user,
};
const actions = {
  async Register({dispatch}, form) {

    await axios.post('register', form)
    let UserForm = new FormData()
    UserForm.append('username', form.username)
    UserForm.append('password', form.password)
    await(dispatch('LogIn', UserForm))
  },
  async LogIn({commit}, loginInfo) {
    console.log('useAuth User ', loginInfo)
    await loginUser(loginInfo)
    .then((userAuth) => {
      console.log('userAuth Return ', userAuth)
      commit('setUser', loginInfo.username)
    })
    .catch((err) => {
      console.log('userAuth Error ', err)
    })

  },
  async CreatePost({dispatch}, post) {
    await axios.post('post', post)
    await dispatch('GetPosts')
  },
  async GetPosts({ commit }) {
    let response = await axios.get('posts')
    commit('setPosts', response.data)
  },
  LogOut({ commit }) {
    commit('LogOut')
  }
};
const mutations = {
  setUser(state, username) {
    state.user = username
  },
  setPosts(state, posts) {
    state.posts = posts
  },
  LogOut(state) {
    state.user = null,
    state.posts = null
  }
};
export default {
  state,
  getters,
  actions,
  mutations
};
