<template>
  <div class="cal-update">
    <v-btn @click="updateCalendarDB">
      Update Calendar
    </v-btn>
    <div v-if="updateStatus" class="updating">
      <h4>UPDATING CALENDAR</h4>
    </div>
    <div v-if="respMsg.status" class="update-status">
      <div v-if="respMsg.status === 200">
        <h4>Calendar Updated</h4>
        <ul>
          <li>Updated Items: {{ respMsg.updates }}</li>
          <li>New Items: {{ respMsg.newRecords }}</li>
        </ul>
      </div>
      <div v-else>
        <h4>Update Failed</h4>
        {{ respMsg }}
      </div>
    </div>
  </div>
</template>

<script>
import { updateCalBase } from "@/utils/calendarService";

export default {
  name: "UpdateCalButton.vue",
  data: () => {
    return {
      respMsg: [],
      updateStatus: false
    }
  },
  methods: {
    async updateCalendarDB() {
      this.updateStatus = true
      this.respMsg = await updateCalBase()
      this.updateStatus = false
    }
  }

}
</script>

<style lang="scss">
.cal-update {
  padding-left: 15px;
  .update-status {
    padding-left: 5px;
  }
  .updating {
    color: red;
  }

}
</style>
