<template>
  <div class = 'ytd-time'>
    <h2>YTD Ice Time Allocation</h2>
    <p>
      <span class="label">Season:</span> {{ ytdInf.season}}<br />
      <span class="label">Ending Date:</span> {{ ytdInf.toDate }}
    </p>
    <table>
      <thead>
        <th>Team</th>
        <th>Hours To Date</th>
      </thead>
      <tbody v-for="team in teamTimes" :key="team.team_code">
        <tr>
          <td>{{ team.team_code }}</td>
          <td class="hrs">{{ team.hrs }}</td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'TeamYtdIce',
  computed: {
    ...mapGetters({
      ytdInf: 'ytdIce',
    }),
    teamTimes() {
      return this.ytdInf.teamTimes
    }
  }
}
</script>

<style lang="scss" scoped>
  .ytd-time {
    display: block;
    width: 80%;
    border: solid 1px black;
    margin: 1rem auto;
    padding: 1rem;
  }
  h2 {
    text-align: center
  }
  .label {
    font-weight: 600;
  }
  table {
    margin: 0 auto;
    td, th {
      width: auto;
      padding-left: .5rem;
    }
    tr {
      border-bottom: 1px solid black;
      .hrs {
        text-align: right;
      }
    }
  }
</style>