<template>
  <div class="venue-use-mo">
    <v-data-table
        :headers="headers"
        :items="items"
        :sort-by="month"
        dense
        hide-default-footer
        ref="table" class="mo-table">
      <template v-slot:item.cost="{ item }">
        $ {{ item.cost }}
      </template>
    </v-data-table>
  </div>
</template>

<script>
export default {
  name: "VenueUseMo",
  props: {
    teamTimes: Array
  },
  data() {
    return {
      headers: [
        {
          text: 'Month',
          align: 'left',
          sortable: true,
          value: 'month',
          dataType: 'String',
          class: 'table-mo'
        },
        {
          text: 'Ice Hours',
          value: 'ice_time',
          total: true,
          dataType: 'Numberic',
          class: 'table-hrs'
        },
        {
          text: 'Ice Cost',
          value: 'cost',
          total: true,
          dataType: 'Currency',
          class: 'table-cost'
        }
      ],

      items: this.teamTimes
    }
  }
}
</script>

<style lang="scss">
  th,
  .theme--light.v-data-table > .v-data-table__wrapper > table > thead > tr:last-child > th {
    font-weight: 800;
    text-align: center;
    width: 33%;
  }
  table > tbody > tr > td {
    text-align: right;
  }
</style>
