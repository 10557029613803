<template>
  <div class="navbar">
    <router-link to="/">
      Home
    </router-link>
    <router-link to="/calendar">
      Calendar
    </router-link>
    <router-link to="/costs">
      Ice Use
    </router-link>
    <button type="button" class="log-out" @click="logOut">Log Out</button>
  </div>
</template>

<script>
export default {
  name: "NavHeader.vue",
  methods: {
    logOut() {
      this.$store.dispatch('LogOut')
      this.$router.push('/login')
    }
  }
}

</script>

<style lang="scss">

.navbar {
  background-color: blue;
  color: #fff;
  font-weight: bold;
  text-align: right;
  a {
    color: #fff;
    text-decoration: none;
    display: inline-block;
    margin-right: 3px;
    margin-top: 3px;
    margin-bottom: 3px;
    padding: 3px 8px;
    background-color: cornflowerblue;
  }
  a:hover {
    background-color: darkblue;
  }
  button.log-out {
    background-color: darkgoldenrod;
    margin-right: 2rem;
    padding: 0 1rem;
  }
}

</style>
