<template>
  <div class="home">
    <h1>Welcome to the Tremors Management Pages</h1>
    <event-list></event-list>
  </div>
</template>

<script>
// @ is an alias to /src
import EventList from '@/components/EventList'

export default {
  name: 'Home',
  components: {
    EventList
  },

}
</script>
