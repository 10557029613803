import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from 'vuex-persistedstate'
import calendar from './calendar'
import auth from './auth'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    isLoading: false
  },
  mutations: {
    UPDATE_LOADING: (state, { loadingValue }) => {
      state.isLoading = loadingValue
    }
  },
  actions: {
    resetLoading: ({commit}) => {
      commit('UPDATE_LOADING', {loadingValue: false})
    },
    setLoading: ({commit}) => {
      commit('UPDATE_LOADING', {loadingValue: true} )
    }
  },
  getters: {
    isLoading: state => {
      return state.isLoading
    }
  },
  modules: {
    calendar,
    auth
  },
  plugins: [createPersistedState()]
})
