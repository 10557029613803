import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import Calendar from '../views/Calendar.vue'
import Costs from '../views/Costs.vue'
import Login from '../views/Login.vue'
import store from '../store/index.js'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
    meta: { reqAuth: true }
  },
  {
    path: '/calendar',
    name: 'Calendar',
    component: Calendar,
    meta: { reqAuth: true }
  },
  {
    path: '/costs',
    name: 'Costs',
    component: Costs,
    meta: { reqAuth: true }
  },
  {
    path: '/login',
    name: 'LogIn',
    component: Login
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  const loggedIn = store.getters.isAuthenicated
  // console.log(`router rail ${JSON.stringify(store)}`)
  if (to.matched.some(record => record.meta.reqAuth) && !loggedIn && to.path !== '/login') {
    next('/login')
  } else {
    next()
  }
})

export default router
