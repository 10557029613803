<template>
  <div class="venue-use">
    <v-container class="venue-table">
      <v-row v-for="(team, index) in venueTable" :key="index" class="month-row">
        <v-col class="col-md-2">{{ team.team }}</v-col>
        <v-col class="col-md-10 mo-details">
          <VenueUseMo :teamTimes="team.times" />
        </v-col>
      </v-row>
    </v-container>
    <div class="action-row">
      <download-excel
        :data = "venueTable"
        class="button"
        name = "tremorsIceCost.xls">
        Download Table
      </download-excel>
    </div>
  </div>
</template>

<script>
import VenueUseMo from "@/components/VenueUseMo";
import { mapGetters } from 'vuex'

export default {
  name: "VenueUse",
  data() {
    return {
      venueFields: {
        Team: 'team',
        Month: 'times.month',
        IceTime: 'times.ice_time',
        Cost: 'times.cost'
      },
    }
  },
  computed: {
    ...mapGetters({
      venueTable: 'venueSummary'
    }),
    venueData() {
      return JSON.stringify(this.venueTable)
    }
  },
  components: {
    VenueUseMo
  },
/*   mounted() {
    this.$store.dispatch('setVenueSummary')
  } */
}
</script>

<style lang="scss">
.action-row {
  text-align: center;
  .button {
    width: 25%;
    padding: 5px;
    background-color: cornflowerblue;
    color: white;
    border-radius: 8px;
    margin-left: auto;
    margin-right: auto;
    cursor: pointer;
  }
}
.venue-use {
  .venue-table{
   .month-row {
     border: solid 1px blue;
   }
  }
}

</style>
